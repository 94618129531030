<template>
  <div>
    <div class="container-fluid center-max-1320-px mt-3 mb-5 p-3">
      <div class="row my-4 justify-content-center">
        <div class="col-12 col-lg-10">
          <h3>Contact</h3>
          <p class="lead">
            Please, fill out as much information as you can to receive a prompt response.
            <br /><br />
            Customer Support <br />
            Email: {{ instanceInfo.supportEmail }} <br />
            Phone: {{ instanceInfo.phoneNumber }}
          </p>
        </div>

        <div class="col-12 col-lg-10">
          <div class="card card-body">
            <form id="request-form" class="row" @submit.prevent="submitForm">
              <div class="col-12">
                <h5 class="my-3">Personal Information</h5>
              </div>
              <div class="col-12 col-lg-6">
                <div class="row mb-3">
                  <label for="request-type" class="col-sm-4 col-form-label">Request</label>
                  <div class="col-sm-8">
                    <select
                      id="request-type"
                      v-model="requestCategory"
                      name="request"
                      class="form-select"
                      aria-label="Request type"
                      required=""
                    >
                      <option value="Unsubscribe Request">Unsubscribe Request</option>
                      <option value="General Question">General question</option>
                      <option value="Technical Question">Technical question</option>

                      <option value="Fraud">Fraud</option>
                      <option value="Refund Request">Refund request</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="row mb-3">
                  <label for="request-username" class="col-sm-4 col-form-label">Username</label>
                  <div class="col-sm-8">
                    <input
                      id="request-username"
                      v-model="username"
                      type="text"
                      name="username"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="row mb-3">
                  <label for="request-email" class="col-sm-4 col-form-label">Email *</label>
                  <div class="col-sm-8">
                    <input
                      id="request-email"
                      v-model="email"
                      type="email"
                      name="email"
                      class="form-control"
                      required=""
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="row mb-3">
                  <label for="request-first-name" class="col-sm-4 col-form-label"
                    >First Name *</label
                  >
                  <div class="col-sm-8">
                    <input
                      id="request-first-name"
                      v-model="firstName"
                      type="text"
                      name="first_name"
                      class="form-control"
                      required=""
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="row mb-3">
                  <label for="request-last-name" class="col-sm-4 col-form-label">Last Name *</label>
                  <div class="col-sm-8">
                    <input
                      id="request-last-name"
                      v-model="lastName"
                      type="text"
                      name="last_name"
                      class="form-control"
                      required=""
                    />
                  </div>
                </div>
              </div>

              <div class="col-12">
                <h5 class="my-3">Card Information</h5>
              </div>

              <div class="col-12 col-lg-4">
                <div class="mb-3">
                  <label for="request-card" class="form-label">Card Number</label>
                  <div class="input-group">
                    <input
                      id="request-card"
                      v-model="cardFirst6"
                      type="text"
                      name="card_first"
                      maxlength="6"
                      class="form-control"
                      placeholder="First 6 digits"
                    />

                    <input
                      v-model="cardLast4"
                      type="text"
                      name="card_last"
                      maxlength="4"
                      class="form-control"
                      placeholder="Last 4 digits"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="mb-3">
                  <label for="request-transaction-date" class="form-label">Transaction Date</label>
                  <input
                    id="request-transaction-date"
                    v-model="transactionDate"
                    type="date"
                    name="transaction_date"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-12">
                <h5 class="my-3">Additional Information</h5>
              </div>

              <div class="col-12">
                <div class="mb-3">
                  <label for="request-description" class="form-label">Description *</label>
                  <textarea
                    id="request-description"
                    v-model="message"
                    name="description"
                    cols="30"
                    rows="3"
                    class="form-control"
                    required=""
                  ></textarea>
                </div>
              </div>

              <button
                id="submitBtn"
                type="submit"
                class="btn btn-lg btn-success w-100"
                :disabled="submitSuccessful || submitFailed"
              >
                Submit
                <span
                  id="loader"
                  class="spinner-border spinner-border-sm visually-hidden"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            </form>
          </div>
          <div v-if="submitSuccessful" class="alert alert-success my-3 alert-dismissible">
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
              @click="clearSuccessMessage"
            >
              <span aria-hidden="true">×</span>
            </button>
            Thank you for contacting us, we have received your message!
          </div>
          <div v-if="submitFailed" class="alert alert-danger my-3 alert-dismissible">
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
              @click="clearFailureMessage"
            >
              <span aria-hidden="true">×</span>
            </button>
            Your message couldn't be delivered. Please try contacting us by email!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { registerContactMessage } from '@/app/api/contact';

export default {
  name: 'Contact',
  inheritAttrs: false,
  props: {
    instanceInfo: {
      type: Object,
      required: false,
    },
  },
  setup() {
    const submitSuccessful = ref(false);
    const submitFailed = ref(false);

    const requestCategory = ref('Unsubscribe Request');
    const username = ref('');
    const email = ref('');
    const firstName = ref('');
    const lastName = ref('');
    const cardFirst6 = ref('');
    const cardLast4 = ref('');
    const transactionDate = ref('');
    const message = ref('');

    const submitForm = () => {
      const contactRequest = {
        requestCategory: requestCategory.value,
        username: username.value,
        email: email.value,
        firstName: firstName.value,
        lastName: lastName.value,
        cardFirst6: cardFirst6.value,
        cardLast4: cardLast4.value,
        transactionDate: transactionDate.value,
        message: message.value,
      };
      registerContactMessage(contactRequest)
        .then(() => {
          submitFailed.value = false;
          submitSuccessful.value = true;
          requestCategory.value = 'Unsubscribe Request';
          username.value = '';
          email.value = '';
          firstName.value = '';
          lastName.value = '';
          cardFirst6.value = '';
          cardLast4.value = '';
          transactionDate.value = '';
          message.value = '';
        })
        .catch(() => {
          submitFailed.value = true;
        });
    };

    const clearSuccessMessage = () => {
      submitSuccessful.value = false;
    };

    const clearFailureMessage = () => {
      submitFailed.value = false;
    };

    return {
      submitSuccessful,
      submitFailed,
      requestCategory,
      username,
      email,
      firstName,
      lastName,
      cardFirst6,
      cardLast4,
      transactionDate,
      message,
      submitForm,
      clearSuccessMessage,
      clearFailureMessage,
    };
  },
};
</script>
